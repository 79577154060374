<template>
  <div class="dir-box">
    <a-collapse
      accordion
      v-model="activeName"
      :destroy-inactive-panels="true"
      @change="handleCollapseChange"
    >
      <a-collapse-panel
        v-for="(item, idx) in dirData"
        :key="idx.toString()"
        :show-arrow="false"
        :forceRender="true"
      >
        <template slot="header">
          <a-tooltip placement="top">
            <template #title>
              <span> {{ item.name }}</span>
            </template>
            <div
              :class="{
                active: activeIndex === idx,
              }"
              @click="handleClick(item, idx)"
              class="ellipsis camearName"
            >
              {{ item.name }}
            </div>
          </a-tooltip>
        </template>
        <a-tree
          v-if="activeName == idx"
          :load-data="onLoadCameraSelectData"
          :treeData="treeData"
          :replaceFields="{
            key: 'id',
            title: 'name',
            children: 'children',
          }"
          :expandedKeys.sync="expandedTreeKeys"
          @select="dirNodeSelect"
        >
          <template slot="customtitle" slot-scope="tags">
            <template>
              <span>{{ tags.name }}</span>
            </template>
          </template>
        </a-tree>
        <!-- <div v-for="item in topCameraList" :key="item.id">
          <span
            class="dir-cameraName ellipsis pointer"
            @click="dirClickCamera(item)"
            >{{ item.cameraName }}</span
          >
        </div> -->
      </a-collapse-panel>
    </a-collapse>
    <div
      v-if="loading"
      class="absolute top-style full-width flex items-center justify-center"
    >
      <a-spin />
      <div
        class="text-lightblue text-center p-h-sm"
        v-if="dirData.length <= 0 && !loading"
      >
        空空如也~
      </div>
    </div>
  </div>
</template>

<script>
import bus from "@/components/js/eventBus";
export default {
  name: "customize",
  props: {},
  components: {},
  data() {
    return {
      dirData: [],
      activeIndex: 0,
      activeName: null,
      treeData: [],
      expandedTreeKeys: [],
      currentPage: 1,
      pageSize: 10000,
      cameraList: [],
      loading: false,
      topCameraList: [],
      showTree: false,
      activeKey: null,
    };
  },
  computed: {},
  mounted() {
    this.getCatalogTopDir();
  },
  methods: {
    getCatalogTopDir() {
      this.loading = true;
      let data = {
        type: 1,
      };
      this.$api.home.getCatalogTopDir(data).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.dirData = res.data.filter((item) => item.showStatus == "1");
          console.log("this.dirData", this.dirData);
        }
      });
    },
    handleClick(val, idx) {
      console.log("选中的目录信息", val, idx, this.treeData);
      this.activeIndex = idx;
      this.getTopDirCameraList(val);
      this.getTreeData(val.name, val.id);
    },
    handleCollapseChange(activeKeys) {
      console.log("当前展开的面板:", activeKeys);
      this.activeKey = activeKeys;
      this.showTree = this.activeName;
      if (this.treeData.length > 0) {
        this.treeData[0].children = [];
        this.$set(this.treeData[0], 'children', [])
      }
      // 在这里处理面板展开/折叠的事件
    },
    getTreeData(name, cameraDirId) {
      console.log(" this.treeData111", this.treeData);
      const data = {
        name: "",
        cameraDirId: cameraDirId ? cameraDirId : this.dirData[0].id,
      };
      this.$api.home.getCatalogTree(data).then((res) => {
        if (res.code == 200) {
          this.treeData = res.data;
        }
      });
    },
    dirClickCamera(item) {
      bus.$emit("play", "", item);
    },
    dirNodeSelect(selectedKeys, e) {
      console.log("selectedKeys, e", selectedKeys, e);
      if (e.node.dataRef.type == "camera") {
        bus.$emit("play", selectedKeys, e.node.dataRef);
      }
      // this.$emit("nodeClick", selectedKeys, e);
    },
    getTopDirCameraList(val) {
      let data = {
        currPage: this.currentPage,
        pageSize: this.pageSize,
        cameraDirId: val.id,
        cameraCustomizeDirId: null,
      };
      this.$api.home.getDirCameraList(data).then((res) => {
        if (res.code === 200) {
          this.topCameraList = res.data;
          console.log("this.topCameraList", this.topCameraList);
          this.loading = false;
        }
      });
    },
    onLoadCameraSelectData(treeNode) {
      console.log("treeNode", treeNode.dataRef);
      if (treeNode.dataRef.isLeaf) {
        return Promise.resolve();
      }
      return new Promise((resolve) => {
        if (treeNode) {
          let data = {
            currPage: this.currentPage,
            pageSize: this.pageSize,
            cameraDirId: treeNode.dataRef.cameraDirId,
            cameraCustomizeDirId: treeNode.dataRef.id,
          };
          this.$api.home.getDirCameraList(data).then((res) => {
            if (res.code === 200) {
              this.cameraList = res.data;
              this.loading = false;
              console.log("this.cameraList", this.cameraList);
              const list = res.data.map((item, index) => {
                item.name = item.cameraName;
                item.scopedSlots = { title: "customtitle" };
                item.isLeaf = true;
                item.type = "camera";
                item.cameraIndex = index;
                return item;
              });
              const flattenData = this.flattenTree(this.treeData);
              const dirList = flattenData.filter(
                (dir) => dir.parentId == treeNode.dataRef.id
              );
              this.$set(treeNode.dataRef, "children", [...dirList, ...list]);
              console.log(
                "flattenData",
                flattenData,
                this.$set(treeNode.dataRef, "children", [...dirList, ...list])
              );

              setTimeout(() => {
                resolve();
              }, 1000);
            }
          });
        }
      });
    },

    flattenTree(data) {
      console.log(data);
      const flattened = [];
      for (const item of data) {
        flattened.push(item);
        if (item.children && item.children.length > 0) {
          flattened.push(...this.flattenTree(item.children));
          console.log("flattened", flattened, item);
        }
      }
      return flattened;
    },
  },
};
</script>

<style lang="less" scoped>
.dir-box {
  /deep/.ant-collapse {
    background-color: #273c5d;
    color: #fff;
  }
  .active {
    background: rgba(46, 155, 255, 0.3);
    padding: 4px;
    border: 1px solid #5677a1;
  }
  /deep/.ant-collapse-content {
    background: rgba(0, 0, 0, 0.44);
    border-top: none;
  }
  /deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
    text-align: center;
    color: #fff;
  }
  /deep/.ant-collapse > .ant-collapse-item {
    border-bottom: none;
  }
  .ant-collapse {
    border: none;
  }
  .camearName {
    text-align: left;
    padding: 2px;
  }
  .dir-cameraName {
    color: #fff;
    margin-left: 10px;
    padding: 0.4rem 0;
    white-space: nowrap;
    list-style: none;
    outline: 0;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 30px;
  }
}
</style>
