<template>
  <div class="home-left relative p-sm full-height hidden">
    <div class="relative" style="height: calc(100% - 220px)">
      <div class="full-height">
        <template v-if="!$g.treeDefinate">
          <home-left-menu :list="subList" @showCom="showComHandle" />
          <home-left-sub-menu
            :list="subMenuList"
            @selectSubTab="selectSubTabHandle"
            v-if="false"
          />
          <!-- <home-bot-sub-menu style="margin-bottom:5px" :list="subMenuList" /> -->
          <vue-scroll :ops="$root.scrollOpsY" style="height: calc(100% - 40px)">
            <template>
              <tree-list
                id="treeDom"
                ref="treeDom"
                v-if="cameralListTree.length > 0"
                :cameralList="cameralListTree"
                :type="currentType"
                @showData="showDataHandle"
                @closeTimer="closeTimerHandle"
              />
              <!-- <tree-list></tree-list> -->
              <div
                v-if="loading"
                class="absolute top-style full-width flex items-center justify-center"
              >
                <a-spin />
              </div>
            </template>
          </vue-scroll>
        </template>
        <template v-else-if="$g.treeDefinate">
          <!--fix之前 <home-left-menu :list="parentList" @showCom="showParentComHandle" /> -->
          <home-left-menu
            :list="dirData.length > 0 ? newParentList2 : newParentList"
            @showCom="showNewParentComHandle"
          />
          <!-- <div class="flex flex-end m-t-sm">
            <div class="wd60" v-show="curParent === 0">
              <home-left-menu
                ref="homeChildMenu"
                :list="subYuanNanList"
                @showCom="showComYunNanHandle"
              />
            </div>
          </div> fix以前-->

          <list-menu
            :list="getChildList"
            ref="homeChildMenu"
            @showCom="showComNewYunNanHandle"
          />

          <vue-scroll
            :ops="$root.scrollOpsY"
            style="height: calc(100% - 80px)"
            v-if="isCustomize"
          >
            <template>
              <tree-list
                :list="parentList"
                ref="treeListDom"
                v-if="cameralListTree.length > 0"
                :cameralList="cameralListTree"
                :type="currentType"
                :typePa="curPaType"
                @showData="showDataHandle"
                @closeYunOrgTimer="closeYunOrgTimerHandle"
                @closeTimer="closeTimerHandle"
              />
              <!-- <tree-list></tree-list> -->
              <div
                v-if="loading"
                class="absolute top-style full-width flex items-center justify-center"
              >
                <a-spin />
              </div>
              <div
                class="text-lightblue text-center p-h-sm"
                v-if="cameralListTree.length <= 0 && !loading"
              >
                空空如也~
              </div>
            </template>
          </vue-scroll>
          <!-- 自定义目录 -->
          <vue-scroll
            :ops="$root.scrollOpsY"
            style="height: calc(100% - 80px)"
            v-else
          >
            <template>
              <customize-dir @nodeClick="treeNodeClick"></customize-dir>
            </template>
          </vue-scroll>
        </template>
      </div>
      <span class="group-handle absolute r0 b0 pointer" @click="toggleGroup">
        <i
          class="icon-default"
          v-show="!showGroup"
          v-if="!$g.treeDefinate"
          title="更多关注"
        ></i>
        <span v-show="!showGroup" v-if="$g.treeDefinate">重点关注</span>
        <i class="icon-backroute" v-show="showGroup" title="返回"></i>
      </span>
    </div>
    <div
      :class="[
        'toggle-container  absolute full-width l0 t0 home-left home-l-bg ellipsis',
        { current: showAnim },
      ]"
      style="height: calc(100% - 230px)"
      v-if="showGroup"
    >
      <home-left-menu
        :list="subList2.filter((e) => e.show)"
        @showCom="toggleComponent"
      />
      <div style="height: calc(100% - 32px)">
        <!--   <vue-scroll :ops="$root.scrollOpsXY" style="height: calc(100% - 40px);"> -->
        <div class="full-height">
          <collect-com-favorit
            v-if="curCom === 0 && hasPermission(1092101010)"
          />
          <camera-group-new v-if="curCom === 1 && hasPermission(1092101011)" />
          <share-guo v-if="curCom === 3" />
          <video-history v-if="curCom === 2" />
          <!-- <template v-else>
            <collect-com v-if="!$g.isDepart" />
            <collect-com-favorit v-else />
          </template> -->
        </div>
        <!--  </vue-scroll> -->
      </div>
    </div>

    <online-statis :data="chartData" />
  </div>
</template>

<script>
// import HomeLeftMenu from './HomeLeftMenu';
import HomeLeftMenu from "@/components/LeftMenu";
import HomeLeftSubMenu from "./HomeLeftSubMenu";
import CustomizeDir from "./customizeDir.vue";
// import TreeList from './TreeList';
import TreeList from "@/components/TreeList";
// import CollectCom from '@/components/CollectCom';
import CollectComFavorit from "@/components/CollectComFavorit";
import OnlineStatis from "./OnlineStatis";
import VideoHistory from "./VideoHistory";
import CameraGroupNew from "@/components/CameraGroupNew";
import ListMenu from "@/components/ListMenu";
import ShareGuo from "./ShareGuo.vue";
import bus from "@/components/js/eventBus";

// import axios from 'axios';
import { mapActions, mapMutations, mapState } from "vuex";
import {
  queryStaticDataList,
  treeDataReCheck,
  // treeOrganiztionCheck,
  createGuid,
  refreshAmapMarks,
  treeOrganiztionCheckTree,
  treeOrganiztionCheckTreeOther,
} from "@/tools";
// import HomeBotSubMenu from './HomeBotSubMenu';
import store from "@/store";
export default {
  name: "HomeLeft",
  data() {
    return {
      newParentList: [
        {
          id: 0,
          name: "公路",
          type: "roadPa",
        },
        {
          id: 1,
          name: "其他",
          type: "roadOther",
        },
      ],
      newParentList2: [
        {
          id: 0,
          name: "公路",
          type: "roadPa",
        },
        {
          id: 1,
          name: "其他",
          type: "roadOther",
        },
        {
          id: 11,
          name: "自定义目录",
          type: "customize",
        },
      ],
      newChildList: {
        0: [
          {
            id: 0,
            name: "单位",
            type: "organization",
          },
          {
            id: 1,
            name: "区域",
            type: "province",
          },
          {
            id: 2,
            name: "路线",
            type: "road",
          },
        ],
        1: [
          {
            id: 3,
            name: "轨道交通",
            type: "railTraffic",
          },
          {
            id: 4,
            name: "客运站",
            type: "noRoadPa",
          },
          {
            id: 5,
            name: "港口码头",
            type: "noRoadPa",
          },
          {
            id: 6,
            name: "口岸",
            type: "noRoadPa",
          },
          {
            id: 7,
            name: "公交",
            type: "noRoadPa",
          },
          {
            id: 8,
            name: "邮政",
            type: "noRoadPa",
          },
          {
            id: 9,
            name: "铁路",
            type: "noRoadPa",
          },
          {
            id: 10,
            name: "民航",
            type: "noRoadPa",
          },
        ],
      },
      isCustomize: true,
      newCurrentComIndex: 0,
      parentList: [
        {
          id: 0,
          name: "公路",
          type: "roadPa",
        },
        {
          id: 1,
          name: "客运站",
          type: "noRoadPa",
        },
        {
          id: 2,
          name: "轨道交通",
          type: "railTraffic",
        },
        {
          id: 3,
          name: "港口码头",
          type: "noRoadPa",
        },
        {
          id: 4,
          name: "口岸",
          type: "noRoadPa",
        },
      ],
      subYuanNanList: [
        {
          id: 2,
          name: "单位",
          type: "organization",
        },
        {
          id: 1,
          name: "区域",
          type: "province",
        },
        {
          id: 0,
          name: "路线",
          type: "road",
        },
      ],
      subList: [
        {
          id: 0,
          name: "路线",
          type: "road",
          sort1: 3,
          sort2: 3,
        },
        {
          id: 1,
          name: "区域",
          type: "province",
          sort1: 1,
          sort2: 2,
        },
        {
          id: 2,
          name: "单位",
          type: "organization",
          sort1: 2,
          sort2: 1,
        },
        /* {
          id: 3,
          name: '应急',
        }, */
        /* {
          id: 3,
          name: '摄像机组',
          type: 'cameralGroup',
        }, */
      ],
      curCom: 0,
      subList2: [
        {
          id: 0,
          name: "我的收藏",
          type: "collect",
          show: true,
        },
        // show: this.hasPermission(1092101010),
        {
          id: 1,
          name: "摄像机组",
          show: this.hasPermission(1092101011),
          type: "cameraGroup2",
        },
        {
          id: 3,
          name: "国办共享",
          show: true,
          type: "share",
        },
        {
          id: 2,
          name: "调阅记录",
          show: true,
          type: "cameraGroup",
        },
      ],
      subMenuList: [
        {
          id: 0,
          value: "1",
          name: "icon-sm-0",
          title: "道路沿线",
          src: require("@/assets/home/icon-sm-0.png"),
        },
        {
          id: 1,
          value: "2",
          name: "icon-sm-1",
          title: "桥梁",
          src: require("@/assets/home/icon-sm-1.png"),
        },
        {
          id: 2,
          value: "3",
          name: "icon-sm-2",
          title: "隧道",
          src: require("@/assets/home/icon-sm-2.png"),
        },
        {
          id: 3,
          value: "4",
          name: "icon-sm-3",
          title: "收费站",
          src: require("@/assets/home/icon-sm-3.png"),
        },
        {
          id: 4,
          value: "5",
          name: "icon-sm-4",
          title: "服务区",
          src: require("@/assets/home/icon-sm-4.png"),
        },
        {
          id: 5,
          value: "6",
          name: "icon-sm-5",
          title: "ETC门架",
          src: require("@/assets/home/icon-sm-5.png"),
        },
        {
          id: 6,
          value: "7",
          name: "icon-sm-6",
          title: "应急指挥车",
          src: require("@/assets/home/icon-sm-6.png"),
        },
        {
          id: 7,
          value: "8",
          name: "icon-sm-7",
          title: "应急物资储备中心",
          src: require("@/assets/home/icon-sm-7.png"),
        },
        {
          id: 8,
          value: "0",
          name: "icon-sm-8",
          title: "其他",
          src: require("@/assets/home/icon-sm-8.png"),
        },
      ],
      // cameralList: [],
      currentCom: 0,
      currentType: "organization",
      loading: false,
      showGroup: false,
      showAnim: false,
      curParent: 0,
      curPaType: "roadPa",
      curItem: { id: 0, type: "roadPa" },
      currentChildItem: {
        id: 0,
        name: "单位",
        type: "organization",
      },
      currentOrganizationId: "",
      dirData: [],
      /* gradeType: 1,  */ //层级
    };
  },
  components: {
    HomeLeftMenu,
    HomeLeftSubMenu,
    TreeList,
    OnlineStatis,
    CameraGroupNew,
    VideoHistory,
    // CollectCom,
    CollectComFavorit,
    ListMenu,
    ShareGuo,
    CustomizeDir,
    // HomeBotSubMenu,
  },
  computed: {
    ...mapState({
      cameralListTree: (state) => state.home.cameralListTree,
      chartData: (state) => state.home.chartData,
      classify: (state) => state.home.classify,
      selectNode: (state) => state.home.selectNode,
      amapIns: (state) => state.home.amapIns,
      mapClick: (state) => state.home.mapClick,
    }),
    getChildList() {
      return this.newChildList[this.newCurrentComIndex];
    },
  },
  watch: {},

  created() {
    if (this.$g.isDepart) {
      this.subList = this.subList.sort((a, b) => a.sort1 - b.sort1);
    } else {
      this.subList = this.subList.sort((a, b) => a.sort2 - b.sort2);
      this.subList2 = this.subList2
        .filter((item) => item.type !== "cameraGroup2")
        .filter((item) => item.type !== "share");
    }
  },

  mounted() {
    if (this.$g.treeDefinate) {
      // this.getYunOrganizationCameral();fix以前
      this.currentType = "organization";
      this.selectYunData(this.currentType);
    } else {
      if (this.$g.isDepart) {
        // this.getProvinceCameral('province');
        // this.getProvinceDepart();fixsxy
        this.currentType = "province";
        this.setComType(this.currentType);
        this.selectData(this.currentType);
      } else {
        /* this.getOrganizationCameral('organization'); 弃用原先的*/
        this.currentType = "organization";
        // this.getYunOrganizationCameral();fix原先
        this.selectYunData(this.currentType);
      }
    }
    this.getCatalogTopDir();
    /*  this.$nextTick(() => {
      console.log(
        'haha',
        this.$refs.treeDom,
        document.getElementById('treeDom')
      );
      this.setTreeDom(this.$refs.treeDom);
    }); */
  },
  methods: {
    ...mapMutations([
      "setCameralListTree",
      "setChartData",
      "setChartTotalData",
      "setClassify",
      "setComType",
      "setSelectNode",
      "setTreeDom",
      "setMapClick",
      "setShowClassify",
    ]),
    ...mapActions(["getCameralList"]),
    getProvinceDepart() {
      // let self = this;
      let params = {
        type: 1,
      };
      // let CancelToken = axios.CancelToken;
      this.loading = true;
      this.setCameralListTree([]);
      this.$api.home
        .getCameraByRegion(
          params /* ,{
        cancelToken: new CancelToken(function executor(c) {
          self.cancel = c
          console.log(c)
          // 这个参数 c 就是CancelToken构造函数里面自带的取消请求的函数，这里把该函数当参数用
        })
      } */
        )
        .then((res) => {
          if (res.code === 200) {
            this.loading = false;
            let data = res.data.map((item) => ({
              ...item,
              title: item.regionName,
              gradeType: params.type + 1,
              isLeaf: false,
              check: false,
              key: createGuid(),
              id: item.regionCode,
              provinceId: item.regionCode,
              type: "province",
              scopedSlots: { title: "customtitle" },
            }));
            this.setCameralListTree(data);
            this.setChartInfoData(data);
            //地图联动
            this.refreshMapMarks();
          }
        });
    },
    showParentComHandle(item) {
      this.ajaxTimer && clearTimeout(this.ajaxTimer);
      this.curParent = item.id;
      this.curPaType = item.type;
      this.curItem = item;
      if (this.curParent === 0) {
        this.currentType = "organization";
        this.currentOrganizationId = "";
        this.$refs.homeChildMenu.resetCurrentIndex();
        this.selectYunData(this.currentType);
      } else if (this.curParent === 1) {
        this.currentType = "";
        this.currentOrganizationId = "9901004074";
        this.getYunParentData("9901004074");
      } else if (this.curParent === 2) {
        this.currentType = "";
        this.currentOrganizationId = "9901004075";
        this.getYunParentData("9901004075");
      } else if (this.curParent === 3) {
        this.currentType = "";
        this.currentOrganizationId = "9901004076";
        this.getYunParentData("9901004076");
      } else if (this.curParent === 4) {
        this.currentType = "";
        this.currentOrganizationId = "9901004077";
        this.getYunParentData("9901004077");
      }
      if (this.curParent !== 0) {
        this.ajaxTimer = setTimeout(() => {
          this.showParentComHandle(this.curItem);
        }, 60 * 1000 * 3); //300000
      }

      this.setMapClick(true);
    },
    showComYunNanHandle(item) {
      let type = item.type;
      this.currentType = type;
      //切换层级
      /* this.gradeType = 1; */

      this.selectYunData(type);
      this.setMapClick(true);
    },
    //新的
    showComNewYunNanHandle(item) {
      this.isCustomize = true;
      //清除expandedTreeKeys
      this.$refs["treeListDom"] &&
        this.$refs["treeListDom"].initExpandedTreeKeys();
      this.ajaxTimer && clearTimeout(this.ajaxTimer);
      console.log("haha", item);
      this.currentChildItem = item;
      this.currentType = item.type;
      //取消请求
      store.commit("clear_cancel");
      if (item.id === 0 || item.id === 1 || item.id === 2) {
        this.selectYunData(item.type);
      } else if (item.id === 3) {
        // this.currentType = '';
        this.currentOrganizationId = "9901004075";
        this.getYunParentData("9901004075");
      } else if (item.id === 4) {
        // this.currentType = '';
        this.currentOrganizationId = "9901004074";
        this.getYunParentData("9901004074");
      } else if (item.id === 5) {
        // this.currentType = '';
        this.currentOrganizationId = "9901004076";
        this.getYunParentData("9901004076");
      } else if (item.id === 6) {
        // this.currentType = '';
        this.currentOrganizationId = "9901004077";
        this.getYunParentData("9901004077");
      } else if (item.id === 7) {
        // this.currentType = '';
        this.currentOrganizationId = "9901004117";
        this.getYunParentData("9901004117");
      } else if (item.id === 8) {
        // this.currentType = '';
        this.currentOrganizationId = "9901004116";
        this.getYunParentData("9901004116");
      } else if (item.id === 9) {
        // this.currentType = '';
        this.currentOrganizationId = "9901004118";
        this.getYunParentData("9901004118");
      } else if (item.id === 10) {
        // this.currentType = '';
        this.currentOrganizationId = "9901004119";
        this.getYunParentData("9901004119");
      } else if (item.id === 11) {
        this.currentType = "";
        this.setCameralListTree([]);
      } else {
        this.currentType = "";
        this.setCameralListTree([]);
        this.amapIns.clearMarker();
      }
      if (item.id !== 0 && item.id !== 1 && item.id !== 2) {
        this.ajaxTimer = setTimeout(() => {
          this.showComNewYunNanHandle(this.currentChildItem);
        }, 60 * 1000 * 3);
      }

      this.setMapClick(true);
    },
    selectYunData(type) {
      this.ajaxTimer && clearTimeout(this.ajaxTimer);
      switch (type) {
        case "road":
          this.getYunRoadCameral();
          //轮询
          this.ajaxTimer = setTimeout(() => {
            this.selectYunData(this.currentType);
          }, 60 * 1000 * 3); //300000
          break;
        case "province":
          this.getYunProvinceCameral();
          //轮询
          this.ajaxTimer = setTimeout(() => {
            this.selectYunData(this.currentType);
          }, 60 * 1000 * 3); //300000
          // this.getProvinceDepart();
          break;
        case "organization":
          this.getYunOrganizationCameral();

          break;
      }
    },
    getYunParentData(organizationId) {
      let data = {
        organizationId,
      };
      this.loading = true;
      this.setCameralListTree([]);
      this.$api.aiCenterY.cameraTreeAccessOffHighway(data).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          // console.error('----------', treeOrganiztionCheck(res.data));
          this.setCameralListTree(
            treeOrganiztionCheckTreeOther(res.data, organizationId, true)
          );
          this.setChartInfoData(
            treeOrganiztionCheckTreeOther(res.data, organizationId, true)
          );
          //联动地图点位
          this.refreshMapMarks();
        }
      });
    },
    refreshMapMarks(highwayFlag = "") {
      if (!this.mapClick) {
        return;
      }
      if (this.selectNode) {
        console.log("this.selectNode", this.selectNode);
        // console.error(11111111111);
        this.selectNode.id = "";
        this.selectNode.parentId = "";
        this.selectNode.cityCode = "";
        if (highwayFlag === 1) {
          this.selectNode.highwayFlag = highwayFlag;
        } else {
          delete this.selectNode.highwayFlag;
        }
        if (this.currentOrganizationId) {
          this.selectNode.orgnizationId = this.currentOrganizationId;
        } else {
          this.selectNode.orgnizationId = "";
        }
        this.setSelectNode(this.selectNode);
      } else {
        let obj = {};
        if (highwayFlag === 1) {
          obj.highwayFlag = highwayFlag;
        }
        if (this.currentOrganizationId) {
          obj.orgnizationId = this.currentOrganizationId;
        }
        this.setSelectNode(obj);
      }
      refreshAmapMarks(this.currentType, this.selectNode);
      bus.$emit("search", this.selectNode);
      console.log(
        "this.currentType, this.selectNode",
        this.currentType,
        this.selectNode
      );
    },
    getYunOrganizationCameral() {
      let data = {
        regionCode: this.$g.regionCode,
        yn: true,
      };
      this.loading = true;
      this.setCameralListTree([]);
      this.$api.home
        .cameraZJFTreeByOrg(data)
        .then((res) => {
          if (res.code === 200) {
            this.loading = false;
            // let formatData = treeDataReCheck(res.data, 'yunOrganization');
            let formatData = treeOrganiztionCheckTree(res.data, true);
            console.log("formatDataformatData", formatData);
            this.setCameralListTree(formatData);
            this.setChartInfoData(formatData);
            //地图联动
            this.refreshMapMarks(1);
          }
          //轮询
          this.autoUpdate();
        })
        .catch(() => {
          //轮询
          this.autoUpdate();
        });
    },
    autoUpdate() {
      this.ajaxTimer && clearTimeout(this.ajaxTimer);
      this.ajaxTimer = setTimeout(() => {
        this.updateYunOrganizationCameral();
      }, 60 * 1000 * 3); //300000 60 * 1000 * 5
    },
    updateYunOrganizationCameral() {
      let data = {
        regionCode: this.$g.regionCode,
        yn: true,
      };
      this.$api.home
        .cameraZJFTreeByOrg(data)
        .then((res) => {
          if (res.code === 200) {
            // let formatData = treeDataReCheck(res.data, 'yunOrganization');
            let formatData = treeOrganiztionCheckTree(res.data, true);
            // debugger;
            this.setChartInfoData(formatData);
            //地图联动
            this.refreshMapMarks(1);
            this.updateOnlineAndTotal(this.cameralListTree, formatData);

            //轮询
            this.autoUpdate();
          }
        })
        .catch(() => {
          this.autoUpdate();
        });
    },
    updateOnlineAndTotal(source, target) {
      // console.log('papa',source,target);
      // debugger;
      for (let i = 0; i < source.length; i++) {
        let src = source[i];
        let tar = target[i];
        src.inerror = tar.inerror;
        src.online = tar.online;
        src.offline = tar.offline;
        src.total = tar.total;
        if (src.children && src.children.length > 0) {
          this.updateOnlineAndTotal(src.children, tar.children);
        }
      }
    },
    getYunProvinceCameral() {
      let data = {
        gradeType: 1,
        params: {
          regionCode: this.$g.regionCode,
        },
      };
      this.loading = true;
      this.setCameralListTree([]);
      this.$api.home.cameraTreeAccessByRegion(data).then((data) => {
        if (data.code === 200) {
          /*  let arr = data.data.info.filter((item) => {
            // debugger;
            return item.regionCode != this.$g.regionCode;
          }); */
          let arr = data.data.info;
          /*   this.gradeType = data.data.hierarchy; */
          this.loading = false;
          let formatData;
          if (this.$g.treeDefinate) {
            formatData = treeDataReCheck(arr, "provinceYun");
            this.setChartRoadInfoData(formatData);
          } else {
            formatData = treeDataReCheck(arr, "province");
            this.setChartInfoData(formatData);
          }
          this.setCameralListTree(formatData);

          // console.error(treeDataReCheck(arr, 'province'));
          //地图联动
          this.refreshMapMarks(1);
        }
      });
    },
    getYunRoadCameral() {
      let data = {
        gradeType: 1,
        params: {
          regionCode: this.$g.regionCode,
        },
      };
      this.loading = true;
      this.setCameralListTree([]);
      this.$api.home.cameraTreeAccessByRoad(data).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          let formatData = treeDataReCheck(res.data.info, "road");
          this.setCameralListTree(treeDataReCheck(res.data.info, "road"));
          this.setChartRoadInfoData(formatData);
          // console.error(treeDataReCheck(res.data.info, 'road'));
          //地图联动
          this.refreshMapMarks(1);
        }
      });
    },
    selectSubTabHandle() {
      this.selectData(this.currentType);
    },
    showComHandle(item) {
      let type = item.type;
      this.currentType = type;
      this.currentCom = item.id;
      this.setClassify("");
      this.setComType(type);
      // this.cancel && this.cancel();//取消上次请求
      //取消请求
      store.commit("clear_cancel");
      if (this.$g.isDepart) {
        this.setCameralListTree([]);
        this.selectData(type);
      } else {
        this.selectYunData(type);
      }
    },
    selectData(type) {
      this.ajaxTimer && clearTimeout(this.ajaxTimer);
      switch (type) {
        case "road":
          if (this.$g.isDepart) {
            this.amapIns && this.amapIns.removeConverLayer();
            this.getRoadStatis();
          } else {
            this.getRoadCameral(type);
          }

          break;
        case "province":
          if (this.$g.isDepart) {
            this.amapIns && this.amapIns.removeConverLayer();
            this.getProvinceDepart();
          } else {
            this.getProvinceCameral(type);
          }
          break;
        case "organization":
          this.amapIns && this.amapIns.removeConverLayer();
          this.getOrganizationCameral(type);
          break;
      }
      //轮询
      this.ajaxTimer = setTimeout(() => {
        this.selectData(this.currentType);
      }, 60 * 1000 * 3); //300000
    },
    getRoadStatis() {
      let data = {};
      this.loading = true;
      this.setCameralListTree([]);
      this.$api.resource.getByRoad(data).then((res) => {
        if (res.code) {
          this.loading = false;
          let arr = this._getNormalStatic(res.data);
          this.setCameralListTree(arr);
          //地图联动
          this.refreshMapMarks();
        }
      });
    },
    _getNormalStatic(data) {
      let keys = Object.keys(data);
      let arr = [];
      for (let i = 0; i < keys.length; i++) {
        let obj = {
          title: keys[i],
          key: `${i}-${createGuid()}`,
          isLeaf: false,
          type: "roadBefore",
        };
        let child = data[keys[i]].map((item) => {
          item.key = `${item.roadId}-${createGuid()}`;
          item.isLeaf = false;
          item.scopedSlots = { title: "customtitle" };
          item.title = item.name;
          item.baseId = item.roadId;
          item.type = "road";
          item.id = item.roadId;
          return item;
        });
        obj.children = child;
        arr.push(obj);
      }
      return arr;
    },
    getRoadCameral(type) {
      let data = {
        /* provinceCode: window.g.regionCode,
        regionCode: window.g.regionCode, */
        provinceCode: "",
        regionCode: "",
        road: "",
        classify: this.classify,
      };
      let params = {
        dimensionType: type,
        data: data,
      };
      this.loading = true;
      /* this.cameralList = [];
      queryStaticDataList(params, (res) => {
        this.loading = false;
        this.cameralList = res.data;
      }); */
      this.setCameralListTree([]);
      queryStaticDataList(params, (res) => {
        this.loading = false;
        // this.cameralList = res.data;
        // console.error(res.data);
        this.setCameralListTree(res.data);
        this.setChartRoadInfoData(res.data);
        //地图联动
        this.refreshMapMarks();
      });
    },
    getProvinceCameral(type) {
      let data = {
        cityCode: "",
        /* provinceCode: window.g.regionCode,
        regionCode: window.g.regionCode, */
        provinceCode: "",
        regionCode: "",
        road: "",
        classify: this.classify,
      };
      let params = {
        dimensionType: type,
        data: data,
      };
      this.loading = true;
      // this.cameralList = [];
      this.setCameralListTree([]);
      queryStaticDataList(params, (res) => {
        this.loading = false;
        // this.cameralList = res.data;
        this.setCameralListTree(res.data);
        this.setChartInfoData(res.data);
        //地图联动
        this.refreshMapMarks();
      });
    },
    // eslint-disable-next-line no-unused-vars
    getOrganizationCameral(type) {
      let data = {
        organizationId: "",
        /* provinceCode: window.g.regionCode,
        regionCode: window.g.regionCode, */
        provinceCode: "",
        regionCode: "",
        road: "",
        classify: this.classify,
      };
      let params = {
        dimensionType: "province",
        data: data,
      };
      this.loading = true;
      /* this.cameralList = [];
      queryStaticDataList(params, (res) => {
        this.loading = false;
        this.cameralList = res.data;
      }); */
      this.setCameralListTree([]);
      queryStaticDataList(params, (res) => {
        this.loading = false;
        // this.cameralList = res.data;
        //fix sxy添加
        let newData = res.data.map((item) => {
          item.provinceId = item.id;
          return item;
        });
        this.setCameralListTree(newData);
        this.setChartInfoData(res.data);
        //地图联动
        this.refreshMapMarks();
      });
    },
    showDataHandle(cameralList) {
      this.cameralList = cameralList;
    },
    toggleGroup() {
      /* this.showGroup = !this.showGroup;
      this.timer = setTimeout(() => {
        this.showAnim = this.showGroup;
      }, 0); */
      clearTimeout(this.timer);
      if (!this.showGroup) {
        this.showGroup = true;
        this.timer = setTimeout(() => {
          this.showAnim = this.showGroup;
        }, 0);
      } else {
        this.showAnim = false;
        this.timer = setTimeout(() => {
          this.showGroup = this.showAnim;
        }, 200);
      }
      this.curCom = this.hasPermission(1092101010)
        ? 0
        : this.hasPermission(1092101011)
        ? 1
        : 2;
    },
    setChartRoadInfoData(data) {
      let chartData = [];
      data.forEach((it) => {
        chartData = chartData.concat(it.children);
      });

      this.setChartData(chartData);
      this.setChartTotalData(chartData);
    },
    setChartInfoData(data) {
      let chartData = [];

      data.forEach((it) => {
        chartData = chartData.concat(it);
      });
      this.setChartData(chartData);
      this.setChartTotalData(chartData);
    },
    toggleComponent(item) {
      this.curCom = item.id;
    },
    closeTimerHandle() {
      this.ajaxTimer && clearTimeout(this.ajaxTimer);
      this.ajaxTimer = setTimeout(() => {
        if (this.$g.isDepart) {
          this.selectData(this.currentType);
        } else if (this.$g.treeDefinate) {
          this.showComNewYunNanHandle(this.currentChildItem);
        } else {
          this.selectYunData(this.currentType);
        }
      }, 60 * 1000 * 3); //300000
    },
    closeYunOrgTimerHandle() {
      this.ajaxTimer && clearTimeout(this.ajaxTimer);
      this.autoUpdate();
      this.autoGetCamera();

      //获取摄像机

      /* this.ajaxTimer = setInterval(() => {
        console.log('haha');
        this.autoGetCamera();
      }, 1000 * 3); */
      /*    this.ajaxTimer = setTimeout(() => { */
      // fixsxy
      /* }, 1000 *  60 * 3);*/
    },
    autoGetCamera() {
      this.ajaxTimer = setTimeout(() => {
        this.$refs["treeListDom"].getOrganizationCamera();
      }, 3 * 60 * 1000);
    },
    getCatalogTopDir() {
      this.loading = true;
      let data = {
        type: 1,
      };
      this.$api.home.getCatalogTopDir(data).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.dirData = res.data.filter((item) => item.showStatus == "1");
          console.log("this.dirData", this.dirData);
        }
      });
    },
    /**
     * 云南需求新增
     */
    showNewParentComHandle(item) {
      if (item.name == "自定义目录") {
        this.loading = false;
      }
      this.isCustomize = false;
      //清除 expandedTreeKeys
      this.$refs["treeListDom"] &&
        this.$refs["treeListDom"].initExpandedTreeKeys();
      this.ajaxTimer && clearTimeout(this.ajaxTimer);
      this.newCurrentComIndex = item.id;
      this.curParent = item.id;
      this.curPaType = item.type;
      this.curItem = item;
      //取消请求
      store.commit("clear_cancel");
      if (this.curPaType === "roadPa") {
        this.currentType = "organization";
        this.currentOrganizationId = "";
        this.$refs.homeChildMenu.resetCurrentIndex(0);
        // this.selectYunData(this.currentType);
        this.currentChildItem = { id: 0, name: "单位", type: "organization" };
        this.showComNewYunNanHandle(this.currentChildItem);
        //显隐classify
        this.setShowClassify(true);
      } else if (this.curPaType === "roadOther") {
        // this.currentType = '';
        // this.currentOrganizationId = '9901004075';
        // this.getYunParentData('9901004075');
        this.$refs.homeChildMenu.resetCurrentIndex(3);
        this.currentChildItem = {
          id: 3,
          name: "轨道交通",
          type: "railTraffic",
        };
        this.showComNewYunNanHandle(this.currentChildItem);
        this.setShowClassify(false);
      }

      /* else if (this.curParent === 1) {
        this.currentType = '';
        this.currentOrganizationId = '9901004074';
        this.getYunParentData('9901004074');
      } else if (this.curParent === 2) {
        this.currentType = '';
        this.currentOrganizationId = '9901004075';
        this.getYunParentData('9901004075');
      } else if (this.curParent === 3) {
        this.currentType = '';
        this.currentOrganizationId = '9901004076';
        this.getYunParentData('9901004076');
      } else if (this.curParent === 4) {
        this.currentType = '';
        this.currentOrganizationId = '9901004077';
        this.getYunParentData('9901004077');
      } */
      /*  if (this.curPaType !== 'roadPa') {
        this.ajaxTimer = setTimeout(() => {
          this.showNewParentComHandle(this.curItem);
        }, 60 * 1000 * 3); //300000 fixsxy
      } */

      this.setMapClick(true);
    },
    // 自定义目录与地图及播放组件关联
    treeNodeClick(val, node) {
      console.log(val, node);
      setTimeout(() => {
        this.$refs["treeListDom"] &&
          this.$refs["treeListDom"].initExpandedTreeKeys();
        // this.$refs["treeListDom"].abc();
        // this.$refs["treeListDom"].treeNodeSelect(val, node);
      }, 1000);
    },
  },
  destroyed() {
    this.timer && clearTimeout(this.timer);
    this.ajaxTimer && clearTimeout(this.ajaxTimer);
  },
};
</script>

<style scoped>
.home-left {
  background: linear-gradient(
    0deg,
    rgba(15, 40, 61, 0.3),
    rgba(46, 86, 142, 0.3)
  );

  height: 100%;
  color: #fff;
}
.home-l-bg {
  background: linear-gradient(0deg, rgba(15, 40, 61, 1), rgba(46, 86, 142, 1));
  transform: translateX(-100%);
  transition: transform 0.4s;
}
.home-l-bg.current {
  transform: translateX(0);
}
.group-handle {
  z-index: 20;
}
.group-handle span {
  display: inline-block;
  padding: 5px 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #c1ddff;
  background: #2b5282;
  vertical-align: top;
}
/* .group-handle::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 50px solid #4679bf;
  border-left: 50px solid transparent;
  right: 0;
  bottom: 0;
} */
.z9 {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 99;
}
.toggle-container {
  background: #182846;
  z-index: 1;
}
.wd60 {
  width: 60%;
}
.top-style {
  top: 50%;
  transform: translateY(-50%);
}
</style>
