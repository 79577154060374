<template>
  <!--  :expandedKeys.sync="expandedTreeKeys" -->
  <div id="treeDom">
    <a-tree
      ref="treeDom"
      id="treeDomWrap"
      :load-data="onLoadCameraSelectData"
      :tree-data="cameralList"
      :on="{ click: () => {} }"
      :expandedKeys.sync="expandedTreeKeys"
      @expand="onExpand"
      @select="treeNodeSelect"
    >
      <template slot="customtitle" slot-scope="tags">
        <template>
          <div
            v-if="tags.type === `camera` && !$g.treeDefinate"
            class="flex items-center"
            :style="getTreeListWd"
            :title="getTitle(tags.khPile, tags.poiName, tags.derection)"
          >
            <span
              :class="{
                'm-r-xs icon-style': true,
                online: String(tags.onlineStatus) === '1',
                offline: String(tags.onlineStatus) === '0',
                error: String(tags.onlineStatus) === '2',
              }"
            ></span>
            <span class="ellipsis full-width">
              {{ tags.title }} {{ tags.poiName }}
              {{ tags.derection | getDirectionArrow }}</span
            >
          </div>
          <div
            v-else-if="tags.type === `camera` && $g.treeDefinate"
            class="flex"
          >
            <span
              :class="{
                'm-r-xs icon-style m-t-sm relative': true,
                online: String(tags.onlineStatus) === '1',
                yunOffline: String(tags.onlineStatus) === '0',
                error: String(tags.onlineStatus) === '2',
              }"
              style="left: -2px"
            ></span>

            <div :style="getTreeListWd">
              <span class="ellipsis full-width block">
                {{ tags.khPile
                }}<span class="m-l-xs" v-show="isRoadtype">{{
                  getDerection(tags)
                }}</span></span
              >
              <span
                class="ellipsis full-width f12 block ellipsis text-lightwhite"
                :title="tags.cameraName"
                >{{ tags.cameraName }}</span
              >
            </div>
          </div>
          <div class="flex fl-bg" :class="{ current: tags.check }" v-else>
            <template>
              <span class="m-r-xs">{{ tags.title }}</span>
              <!--               <span class="m-r-xs" v-else style="color:red">无线路</span> -->
            </template>
            <span class="f12">
              (<span class="text-lightblue">{{ tags.online }}</span
              ><span class="m-l-xs m-r-xs">/</span>{{ tags.total }})
            </span>
          </div>
        </template>
      </template>
    </a-tree>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import bus from "@/components/js/eventBus";
// import { treeData } from '@/tools';
let tempPos = "0-0";
let tempKey = "";

import {
  queryStaticDataList,
  refreshAmapMarks,
  DIRECTOINARROW,
  treeDataReCheck,
  treeTrackCameraCheck,
  createGuid,
} from "@/tools";
export default {
  name: "TreeList",
  props: {
    cameralList: {
      type: Array,
      default() {
        return [];
      },
    },
    type: {
      type: String,
    },
    isMap: {
      type: Boolean,
      default: true,
    },
    clickCamera: {
      type: Boolean,
      default: false,
    },
    typePa: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cameraTreeDataPath: {
        organization: ["province", "organization", "road", "camera"],
        road: ["road", "province", "camera"],
        province: ["province", "road", "camera"],
      },
      gradeType: 1,
      isRoadtype: this.typePa !== "roadPa" ? false : true,
      expandedTreeKeys: [],
      expandedLoadKey: [],
      tempObjArr: [],
      currentOrganizationId: "",
    };
  },
  computed: {
    ...mapState({
      classify: (state) => state.home.classify,
      amapIns: (state) => state.home.amapIns,
      clickCameraList: (state) => state.home.clickCameraList,
      chartTotalData: (state) => state.home.chartTotalData,
      searchValue: (state) => state.home.searchValue,
      searchCameraId: (state) => state.home.searchCameraId,
      searchType: (state) => state.home.searchType,
      isSearchBtn: (state) => state.home.isSearchBtn,
      searchOk: (state) => state.home.searchOk,
      treeListWd: (state) => state.home.treeListWd,
      // searchOrganizationId: (state) => state.home.searchOrganizationId,
    }),
    getTreeListWd() {
      let wd = this.treeListWd - 120;
      return { width: `${wd}px` };
    },
  },
  watch: {},
  mounted() {
    this.setTreeDom(this.$refs.treeDom);
    bus.$on("play", (val, e) => {
      this.dirNodeSelect(val, e);
    });
  },
  methods: {
    ...mapMutations([
      "setCameralListTree",
      "setChartData",
      "setSelectNode",
      "setClickCameraList",
      "setSearchValue",
      "setSearchCameraId",
      // 'setSearchOrganizationId',
      "setTreeDom",
      "setSearchType",
      "setIsSearchBtn",
      "setSearchOk",
    ]),
    ...mapActions(["getCameralList"]),
    getDerection(tags) {
      return DIRECTOINARROW[tags.derection];
    },
    getTitle(khPile, poiName, derection) {
      return `桩号:${khPile},兴趣点名称:${poiName},方向:${DIRECTOINARROW[derection]}`;
    },
    /**
     * 查询tree下级数据
     * @param treeNode
     * @returns {Promise<unknown>}
     */
    getProvinceData(treeNode, resolve) {
      let vm = this;
      let roadId = treeNode.dataRef.roadId;

      if (this.type === "road") {
        // let roadId = treeNode.dataRef.roadId;

        let data = {
          gradeType: 2,
          params: {
            regionCode: this.$g.regionCode,
            roadId,
          },
        };
        // this.loading = true;
        // this.cameralListTree([]);
        this.$api.home.cameraTreeAccessByRoad(data).then((res) => {
          if (res.code === 200) {
            treeNode.dataRef.children = treeDataReCheck(
              res.data.info,
              "camera"
            );
            let selectTreeRoadData = [...vm.cameralList];
            this.setCameralListTree(selectTreeRoadData);
            resolve();
          }
        });
      } else if (this.type === "province") {
        /*  let gradeType =
              this.gradeType === 3 ? this.gradeType : this.gradeType + 1; */
        let gradeType = treeNode.dataRef.gradeType;
        let regionCode = treeNode.dataRef.regionCode;
        let data = {
          gradeType,
          params: {
            regionCode,
            roadId: gradeType === 3 ? roadId : "",
          },
        };
        this.$api.home.cameraTreeAccessByRegion(data).then((data) => {
          if (data.code === 200) {
            /*  this.gradeType = data.data.hierarchy; */
            treeNode.dataRef.children = treeDataReCheck(
              data.data.info,
              gradeType === 2 ? "provinceRoad" : "camera"
            );
            let selectTreeRoadData = [...vm.cameralList];
            this.setCameralListTree(selectTreeRoadData);
            resolve();
          }
        });
      } else if (this.type === "organization") {
        let organizationId = treeNode.dataRef.organizationId;
        let data = {
          organizationId,
        };
        this.$api.home.cameraTreeByOrgInfo(data).then((res) => {
          if (res.code === 200) {
            treeNode.dataRef.children = treeDataReCheck(res.data, "camera");
            let selectTreeRoadData = [...vm.cameralList];
            this.setCameralListTree(selectTreeRoadData);
            //调用父方法，刷新状态
            this.$parent.updateYunOrganizationCameral();
            resolve();
          }
        });
      }
    },
    onLoadCameraSelectData(treeNode) {
      let vm = this;
      console.log("haha", treeNode.dataRef);
      return new Promise((resolve) => {
        let type = treeNode.dataRef.type;
        //已有子节点，不重复加载

        if (type === "roadBefore") {
          resolve();

          return;
        }
        if (treeNode.dataRef.children) {
          resolve();
          return;
        }
        if (!this.$g.treeDefinate) {
          if (this.$g.isDepart) {
            if (this.type === "organization") {
              let expandData = this.treeNodeExpandPathDataCheck(treeNode);
              vm.getCameraSelectTreeData(type, expandData, (data) => {
                for (var i = 0, lens = data.length; i < lens; i++) {
                  data[i].provinceId = expandData;
                }
                treeNode.dataRef.children = data;
                if (vm.type === "road") {
                  let selectTreeRoadData = [...vm.cameralList];
                  // this.$emit('showData', selectTreeRoadData);
                  this.setCameralListTree(selectTreeRoadData);
                }
                if (vm.type === "organization") {
                  // console.log('haha',data,treeNode.dataRef.children,vm.cameralList)
                  let selectTreeOrgData = [...vm.cameralList];
                  // console.log('organization --------');
                  // this.$emit('showData', selectTreeOrgData);
                  this.setCameralListTree(selectTreeOrgData);
                }
                if (vm.type === "province") {
                  let selectTreeRegionData = [...vm.cameralList];
                  // this.$emit('showData', selectTreeRegionData);
                  this.setCameralListTree(selectTreeRegionData);
                }
                resolve();
              });
            } else if (this.type === "province") {
              let regionCode = treeNode.dataRef.regionCode;
              let gradeType = treeNode.dataRef.gradeType;
              let roadId = treeNode.dataRef.roadId;
              let regionCodes = treeNode.dataRef.regionCodes;
              if (gradeType < 4) {
                let params = {
                  type: gradeType,
                  regionCode,
                  regionCodes,
                };
                this.$api.home.getCameraByRegion(params).then((res) => {
                  if (res.code === 200) {
                    treeNode.dataRef.children = res.data.map((item) => {
                      if (gradeType === 2) {
                        item.title = item.regionNames;
                        item.id = item.regionCode;
                        item.provinceId = item.regionCodes;
                        item.type = "province";
                      } else if (gradeType === 3) {
                        item.title = item.roadName;
                        item.id = item.roadId;
                        item.type = "road";
                      }
                      item.gradeType = params.type + 1;
                      item.isLeaf = false;
                      item.scopedSlots = { title: "customtitle" };
                      item.check = false;
                      item.key = createGuid();
                      return item;
                    });
                    let selectTreeRoadData = [...vm.cameralList];
                    this.setCameralListTree(selectTreeRoadData);
                    resolve();
                  }
                });
              } else {
                let params = {
                  roadId,
                  regionCode,
                  regionCodes,
                };
                this.$api.home.getCameraListByRoad(params).then((res) => {
                  if (res.code === 200) {
                    treeNode.dataRef.children = res.data.map((item) => {
                      item.isLeaf = true;
                      item.type = "camera";
                      item.title = item.khPile;
                      item.scopedSlots = { title: "customtitle" };
                      return item;
                    });
                    let selectTreeRoadData = [...vm.cameralList];
                    this.setCameralListTree(selectTreeRoadData);
                    resolve();
                  }
                });
              }
            } else if (this.type === "road") {
              if (treeNode.dataRef.specialType === "road") {
                let nval = treeNode.dataRef.regionCode;
                let parent = treeNode.$parent.dataRef;
                // console.error('haha', parent);
                queryStaticDataList(
                  {
                    dimensionType: "camera",
                    data: {
                      provinceCode: parseInt(nval),
                      regionCode: parseInt(nval),
                      organizationType: "10",
                      roadId: parent.roadId,
                    },
                  },
                  (res) => {
                    let childrenData = res.data;
                    treeNode.dataRef.children = childrenData;
                    let selectTreeRoadData = [...vm.cameralList];
                    // this.$emit('showData', selectTreeRoadData);
                    this.setCameralListTree(selectTreeRoadData);
                    resolve();
                  }
                );
              } else {
                let roadId = treeNode.dataRef.roadId;
                this.clickRoads(roadId, treeNode, resolve);
              }
            }
          } else if (this.$g.treeProvince) {
            this.getProvinceData(treeNode, resolve);
          } else {
            let expandData = this.treeNodeExpandPathDataCheck(treeNode);
            vm.getCameraSelectTreeData(type, expandData, (data) => {
              for (var i = 0, lens = data.length; i < lens; i++) {
                data[i].provinceId = expandData;
              }

              treeNode.dataRef.children = data;
              if (vm.type === "road") {
                let selectTreeRoadData = [...vm.cameralList];
                // this.$emit('showData', selectTreeRoadData);
                this.setCameralListTree(selectTreeRoadData);
              }
              if (vm.type === "organization") {
                let selectTreeOrgData = [...vm.cameralList];
                // this.$emit('showData', selectTreeOrgData);
                this.setCameralListTree(selectTreeOrgData);
              }
              if (vm.type === "province") {
                let selectTreeRegionData = [...vm.cameralList];
                // this.$emit('showData', selectTreeRegionData);
                this.setCameralListTree(selectTreeRegionData);
              }
              resolve();
            });
          }
        } else {
          let roadId = treeNode.dataRef.roadId;
          if (this.typePa === "roadPa") {
            if (this.type === "road") {
              // let roadId = treeNode.dataRef.roadId;

              let data = {
                gradeType: 2,
                params: {
                  regionCode: this.$g.regionCode,
                  roadId,
                },
              };
              // this.loading = true;
              // this.cameralListTree([]);
              this.$api.home.cameraTreeAccessByRoad(data).then((res) => {
                if (res.code === 200) {
                  treeNode.dataRef.children = treeDataReCheck(
                    res.data.info,
                    "camera"
                  );
                  let selectTreeRoadData = [...vm.cameralList];
                  this.setCameralListTree(selectTreeRoadData);
                  resolve();
                }
              });
            } else if (this.type === "province") {
              /*  let gradeType =
              this.gradeType === 3 ? this.gradeType : this.gradeType + 1; */
              let gradeType = treeNode.dataRef.gradeType;
              let regionCode = treeNode.dataRef.regionCode;
              let data = {
                gradeType,
                params: {
                  regionCode,
                  roadId: gradeType === 3 ? roadId : "",
                },
              };
              this.$api.home.cameraTreeAccessByRegion(data).then((data) => {
                if (data.code === 200) {
                  /*  this.gradeType = data.data.hierarchy; */
                  treeNode.dataRef.children = treeDataReCheck(
                    data.data.info,
                    gradeType === 2 ? "provinceRoad" : "camera"
                  );
                  let selectTreeRoadData = [...vm.cameralList];
                  this.setCameralListTree(selectTreeRoadData);
                  resolve();
                }
              });
            } else if (this.type === "organization") {
              let organizationId = treeNode.dataRef.organizationId;
              let data = {
                organizationId,
              };
              this.$api.home.cameraTreeByOrgInfo(data).then((res) => {
                if (res.code === 200) {
                  treeNode.dataRef.children = treeDataReCheck(
                    res.data,
                    "camera"
                  );
                  let selectTreeRoadData = [...vm.cameralList];
                  this.setCameralListTree(selectTreeRoadData);
                  resolve();
                }
              });
            }
          } else {
            let organizationId = treeNode.dataRef.organizationId;
            let data = {
              organizationId,
              regionCode: this.$g.regionCode,
            };
            this.$api.aiCenterY
              .getTreeAccessOffHighwayCamera(data)
              .then((res) => {
                if (res.code === 200) {
                  treeNode.dataRef.children = treeDataReCheck(
                    res.data,
                    "camera",
                    this.type
                  );
                  let selectTreeRoadData = [...vm.cameralList];
                  this.setCameralListTree(selectTreeRoadData);
                  resolve();
                }
              });
          }
        }
        if (this.type === "organization") {
          this.$emit("closeYunOrgTimer");
        } else {
          this.$emit("closeTimer");
        }
      });
    },
    getOrganizationCamera() {
      //  let organizationId = treeNode.dataRef.organizationId;
      this.cameraTimer && clearTimeout(this.cameraTimer);
      let data = {
        organizationId: this.currentOrganizationId,
      };
      this.$api.home.cameraTreeByOrgInfo(data).then((res) => {
        if (res.code === 200) {
          let currentCameraList = treeDataReCheck(res.data, "camera");
          console.log("haha", currentCameraList);
          // let selectTreeRoadData = [...this.cameralList];
          /* let selectTreeRoadData = this.formatTimeCameraList(
            this.cameralList,
            currentCameraList
          );
          
          this.setCameralListTree(selectTreeRoadData); */
          this.formatTimeCameraList(this.cameralList, currentCameraList);
          this.cameraTimer = setTimeout(() => {
            this.getOrganizationCamera();
          }, 3 * 60 * 1000);
        }
      });
    },
    formatTimeCameraList(cameraList, currentCameraList) {
      for (let i = 0; i < cameraList.length; i++) {
        if (cameraList[i].organizationId === this.currentOrganizationId) {
          cameraList[i]["children"] = currentCameraList;
        } else if (
          cameraList[i]["children"] &&
          cameraList[i]["children"].length > 0
        ) {
          this.formatTimeCameraList(
            cameraList[i]["children"],
            currentCameraList
          );
        }
      }
      /*       return cameraList; */
    },
    clickRoads(roadId, treeNode, resolve) {
      let data = {
        roadId,
      };
      /* let roadListCopy = this.roadLists.slice(); */

      this.$api.resource.getByProvince(data).then((res) => {
        if (res.code === 200) {
          let arr = res.data.map((item) => {
            item.key = `provice-${createGuid()}`;
            item.title = item.name;
            item.scopedSlots = { title: "customtitle" };
            item.isLeaf = false;
            item.specialType = "road";
            item.baseId = item.regionCode;
            item.id = item.regionCode;
            item.type = "province";
            item.provinceId = item.regionCode;
            return item;
          });
          treeNode.dataRef.children = arr;
          let selectTreeRoadData = [...this.cameralList];
          this.setCameralListTree(selectTreeRoadData);
          resolve();
        }
      });
    },
    getCameraSelectTreeData(currtype, expandData, callback) {
      let childrenData = [],
        type = "";
      let path = this.cameraTreeDataPath[this.type];
      type = path[path.indexOf(currtype) + 1];
      if (type === "road" && expandData.provinceCode) {
        expandData.groupbyRoadType = false;
      }

      queryStaticDataList(
        {
          dimensionType: type,
          data: expandData,
        },
        (res) => {
          childrenData = res.data;
          if (typeof callback === "function") {
            callback(childrenData);
          }
        }
      );
    },
    /**
     * 查询节点父节点信息
     * @param node
     */
    treeNodeExpandPathDataCheck(node) {
      let path = this.cameraTreeDataPath[this.type],
        newKsys = {};

      let getParentKeyArray = (node) => {
        let keys = [],
          newNode = node;
        for (let i = 0; i < 10; i++) {
          let dataref = newNode.$parent.dataRef;
          if (dataref) {
            newNode = newNode.$parent;
            //console.info('getParent ', dataref);
            if (dataref.type !== "roadBefore") keys.push(dataref.baseId);
          }
        }
        // 倒序
        return keys.reverse();
      };
      let pathValAr = getParentKeyArray(node);
      if (node.dataRef.type !== "roadBefore")
        pathValAr.push(node.dataRef.baseId);
      path.forEach((vo, idx) => {
        let nval = pathValAr[idx];
        if (!nval) {
          return;
        }
        switch (vo) {
          case "province":
            newKsys.provinceCode = parseInt(nval);
            newKsys.regionCode = parseInt(nval);
            newKsys.oldRoadType = 0;
            // newKsys.organizationType = '10';fix sxy
            break;
          case "city":
            newKsys.cityCode = parseInt(nval);
            break;
          case "organization":
            newKsys.organizationType = "20";
            newKsys.organizationId = nval;
            break;
          case "road":
            newKsys.organizationType = "20";
            newKsys.road = nval;

            break;
        }
      });
      newKsys.classify = this.classify;
      return newKsys;
    },
    filterTreeData(dataRef) {
      if (this.type === "province") {
        dataRef.type2 = "province_region";
        dataRef.parentId = dataRef.regionCode;
        if (dataRef.gradeType === 2) {
          dataRef.cityCode = dataRef.regionCode;
        }

        if (dataRef.gradeType === 3) {
          dataRef.roadId2 = dataRef.baseId;
        }
      } else if (this.type === "organization") {
        dataRef.type2 = "roadBefore_organ";
        dataRef.id = dataRef.organizationId;
      } else if (this.type === "road") {
        dataRef.type2 = "road_road";
      }
    },
    initExpandedTreeKeys() {
      this.expandedTreeKeys = [];
      tempPos = "0-0";
      tempKey = "";
    },
    onExpand(expandedKeys, { expanded, node }, bool) {
      // console.log('kaka', expandedKeys );
      // this.tempObjArr = JSON.parse(JSON.stringify(expandedKeys));
      // console.log('haha', this.tempObjArr);
      // debugger;
      if (this.$g.isDepart) {
        return;
      }
      let pos = node.pos;
      let currentKey = node.eventKey;
      let posLen = pos.split("-").length;
      let tempPosLen = tempPos.split("-").length || 2;

      if (expanded) {
        if (expandedKeys.length > 0) {
          if (posLen === tempPosLen) {
            if (tempKey) {
              expandedKeys.splice(expandedKeys.indexOf(tempKey), 1);
            }
          } else if (posLen < tempPosLen) {
            expandedKeys.reverse().splice(1, Math.abs(posLen - tempPosLen - 1));
            expandedKeys.reverse();
          } /*  else if (posLen > tempPosLen) {
            expandedKeys = [...this.expandedTreeKeys, currentKey];
          } */
          tempPos = pos;
          tempKey = currentKey;
        }
      } else {
        tempPos = "";
        tempKey = "";
        if (bool) {
          expandedKeys.reverse().splice(1, Math.abs(posLen - tempPosLen));
          expandedKeys.reverse();
        }
      }
      this.expandedTreeKeys = expandedKeys;

      // console.log('pp', this.expandedTreeKeys);

      /*   if (expanded) {
        if (expandedKeys.length > 0) {
          expandedKeys.splice(0, expandedKeys.length - 1);
        }
      } */
      // this.expandedTreeKeys = expandedKeys;
      // console.log();
    },
    /**
     * 树节点点击
     * @param selectedKeys
     * @param e
     */
    treeNodeSelect(selectedKeys, e) {
      /* if(selectedKeys.length>0){ */
      //设置点击展开收缩节点
      console.log("打开对应的节点", e, selectedKeys);

      let type = e.node.dataRef.type;
      let selectedKey = e.node.dataRef.key;
      if (!e.node.expanded) {
        this.expandedTreeKeys = [...this.expandedTreeKeys, selectedKey];
        // console.log('更新过的', e, this.expandedTreeKeys);
        this.onExpand(this.expandedTreeKeys, {
          expanded: !e.node.expanded,
          node: e.node,
        });
      } /*fixsxy*/ /*  else {
        this.expandedTreeKeys = this.expandedTreeKeys.filter(
          (item) => item !== selectedKey
        );
      }  */

      //设置check状态

      let copyCamera = JSON.parse(JSON.stringify(this.cameralList));
      e.node.dataRef.check = e.node.dataRef.check ? false : true;
      let cameraList = treeTrackCameraCheck(copyCamera, e.node.dataRef);
      this.setCameralListTree(cameraList);
      if (type === "last" && this.type === "organization") {
        this.currentOrganizationId = e.node.dataRef.organizationId;
      }
      //设置状态结束
      //清空搜索框
      if (this.searchValue) {
        this.setSearchValue("");
      }
      if (this.isSearchBtn) {
        this.setIsSearchBtn(false);
      }
      if (this.searchCameraId) {
        this.setSearchCameraId(null);
      }
      if (this.searchType) {
        this.setSearchType("camera");
      }
      //清空搜索状态
      if (this.searchOk) {
        this.setSearchOk(false);
      }

      // this.mapSearchKeywords = "";
      // 清空搜索关键字
      // console.log('this/cameraSelectTabModel==>', this.cameraSelectTabModel);
      // debugger
      let dataRef = e.node.dataRef;

      // eslint-disable-next-line no-empty
      if (!this.$g.treeDefinate) {
        if (this.$g.treeProvince) {
          this.filterTreeData(dataRef);
        }
      } else {
        if (this.typePa === "roadPa") {
          if (this.type === "province") {
            dataRef.type2 = "province_region";
            dataRef.parentId = dataRef.regionCode;
            if (dataRef.gradeType === 2) {
              dataRef.cityCode = dataRef.regionCode;
            }

            if (dataRef.gradeType === 3) {
              dataRef.roadId2 = dataRef.baseId;
            }
          } else if (this.type === "organization") {
            dataRef.type2 = "roadBefore_organ";
            dataRef.id = dataRef.organizationId;
          } else if (this.type === "road") {
            dataRef.type2 = "road_road";
          }
          dataRef.highwayFlag = 1;
        } else {
          dataRef.type2 = "organ_other";
          dataRef.id = dataRef.organizationId;
        }
      }
      // this.selectedNode = e.node.dataRef;fixsxy
      if (e.node.dataRef.type !== "camera") {
        this.setSelectNode(
          e.node.dataRef
        ); /* 当点击摄像机时会清掉组织单位等参数，在此不能执行*/
      }
      //select选择之后，刷新饼图,刷新地图
      if (dataRef.type !== "camera") {
        let chartData = [];
        if (dataRef.type === "roadBefore") {
          chartData = dataRef.children;
        } else {
          chartData.push(dataRef);
        }
        if (dataRef.check) {
          this.setChartData(chartData);
        } else {
          //如果只是公路则清空，否则不清空organizationId

          if (this.typePa === "roadPa") {
            dataRef.id = "";
          } else {
            dataRef.id = dataRef.originalOrganizationId;
          }
          dataRef.parentId = "";
          dataRef.cityCode = "";
          dataRef.roadId2 = "";
          this.setChartData(this.chartTotalData);
        }
        this.onLoadCameraSelectData(e.node); //fixsxy
        // this.refreshAmapMarks(this.eventRegionCode, dataRef);fix
        refreshAmapMarks(this.type, dataRef);
        // console.error(this.$refs);
      }

      if (["region", "organization"].indexOf(this.cameraSelectTabModel) != -1) {
        if (["road", "organization"].indexOf(dataRef.type) != -1) {
          //点击区块
          this.amapIns.switch2AreaNode(dataRef.provinceId.provinceCode);
        }
      }
      if (["city", "province"].indexOf(dataRef.type) >= 0) {
        // console.error('hahasxy-------------------');
        //点击区块
        this.amapIns.switch2AreaNode(dataRef.id);
      }
      if (
        (dataRef.type === "camera" && this.isMap) /*  &&
          String(dataRef.onlineStatus) === '1' */ ||
        (dataRef.type === "camera" &&
          this.isMap &&
          dataRef.typePa === "railTraffic")
      ) {
        //点击摄像头
        let cameraInfo = dataRef;
        console.log("cameraInfo11", cameraInfo);
        // console.error('ppp-----', cameraInfo);
        this.amapIns.cameraInfoWindowShow({
          data: {
            position: [cameraInfo.longitude, cameraInfo.latitude],
            onlineStatus: cameraInfo.onlineStatus,
            cameraName: cameraInfo.cameraName,
            cameraId: cameraInfo.cameraId,
            cameraNum: cameraInfo.cameraNum,
            organizationId: cameraInfo.organizationId,
            regionCode: cameraInfo.regionCode,
            roadId: cameraInfo.roadId,
            kmPile: cameraInfo.kmPile,
            hmPile: cameraInfo.hmPile,
            classify: cameraInfo.classify,
            cameraType: cameraInfo.cameraType,
            zoomlevel: cameraInfo.zoomlevel,
            source: cameraInfo.source,
            khPile: cameraInfo.khPile,
            collectFlag: cameraInfo.favoriteFlag,
            // resolutionValue: dataRef.typePa === 'railTraffic' ? 1 : 0,fix以前
            resolutionValue:
              cameraInfo.transcodingId === "1354354698693513216" ? 1 : 0,
            resolutionValue2:
              cameraInfo.transcodingId === "1354354698693513216" ? 1 : 0,
          },
        });

        this.amapIns.addVideoMarker(dataRef);
      } else if (
        this.clickCamera &&
        dataRef.type === "camera" /* &&
        String(dataRef.onlineStatus) === '1' */
      ) {
        //点击摄像头
        // eslint-disable-next-line no-unused-vars
        let cameraInfo = dataRef;
        // eslint-disable-next-line no-unused-vars
        let newCameraList = [
          /* ...this.clickCameraList,
          {
            onlineStatus: cameraInfo.onlineStatus,
            cameraName: cameraInfo.cameraName,
            cameraId: cameraInfo.cameraId,
            cameraNum: cameraInfo.cameraNum,
            organizationId: cameraInfo.organizationId,
            regionCode: cameraInfo.regionCode,
            roadId: cameraInfo.roadId,
            kmPile: cameraInfo.kmPile,
            hmPile: cameraInfo.hmPile,
            classify: cameraInfo.classify,
            cameraType: cameraInfo.cameraType,
            zoomlevel: cameraInfo.zoomlevel,
            source: cameraInfo.source,
            khPile: cameraInfo.khPile,
            collectFlag: cameraInfo.favoriteFlag,
          }, */
        ];
        // this.setClickCameraList(newCameraList);
      }
      //如果部平台
      if (this.$g.isDepart) {
        // console.log(dataRef);
        if (dataRef.type === "province") {
          if (dataRef.check) {
            this.amapIns.addConverLayer(dataRef.provinceId);
          } else {
            this.amapIns.removeConverLayer();
          }
        } /* else {
          this.amapIns.removeConverLayer();
        } */
      }
    },
    // 自定义目录节点事件
    dirNodeSelect(selectedKeys, e) {
      console.log("打开对应的节点", e, selectedKeys);
      let dataRef = e;
      // if (
      //   (dataRef.type === "camera" && this.isMap) /*  &&
      //     String(dataRef.onlineStatus) === '1' */ ||
      //   (dataRef.type === "camera" &&
      //     this.isMap &&
      //     dataRef.typePa === "railTraffic")
      // ) {
      //点击摄像头
      let cameraInfo = dataRef;
      console.log("cameraInfo", cameraInfo);
      // console.error('ppp-----', cameraInfo);
      this.amapIns.cameraInfoWindowShow({
        data: {
          position: [cameraInfo.longitude, cameraInfo.latitude],
          onlineStatus: cameraInfo.onlineStatus,
          cameraName: cameraInfo.cameraName,
          cameraId: cameraInfo.cameraId,
          cameraNum: cameraInfo.cameraNum,
          organizationId: cameraInfo.organizationId,
          regionCode: cameraInfo.regionCode,
          roadId: cameraInfo.roadId,
          kmPile: cameraInfo.kmPile,
          hmPile: cameraInfo.hmPile,
          classify: cameraInfo.classify,
          cameraType: cameraInfo.cameraType,
          zoomlevel: cameraInfo.zoomlevel,
          source: cameraInfo.source,
          khPile: cameraInfo.khPile,
          collectFlag: cameraInfo.favoriteFlag,
          // resolutionValue: dataRef.typePa === 'railTraffic' ? 1 : 0,fix以前
          resolutionValue:
            cameraInfo.transcodingId === "1354354698693513216" ? 1 : 0,
          resolutionValue2:
            cameraInfo.transcodingId === "1354354698693513216" ? 1 : 0,
        },
      });

      this.amapIns.addVideoMarker(dataRef);
    },
    handlePlay(title) {
      console.error(title);
    },
  },
  destroyed() {
    this.cameraTimer && clearTimeout(this.cameraTimer);
  },
};
</script>

<style scoped>
.icon-style {
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  transform-origin: center center;
}
.online {
  background: #5cc189;
}
.offline {
  background: #a7b1be;
}

.error {
  background: #ef5844;
}
.fl-bg.current {
  background: #424e65;
  padding: 0 10px;
}
</style>
